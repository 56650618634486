<template>
  <div class="mx-2 text-sm select-none">
    <div>
      <h3 class="flex text-base font-bold text-gray-800">
        Development feature flags
      </h3>
      <p class="italic font-xs">
        In-development features and test options.
      </p>
    </div>

    <template v-for="group in groups">
      <div class="my-2">
        <h3
          class="block text-sm font-medium leading-5 text-gray-700 cursor-pointer hover:underline"
          @click.prevent="group.open = !group.open"
        >
          <fa-icon
            v-if="group.open"
            icon="caret-down"
            class="mr-1"
          />
          <fa-icon
            v-else
            icon="caret-right"
            class="mr-1"
          />
          {{ group.name }} ({{ group.items.length }})
        </h3>
        <div
          v-if="group.open"
          class="p-2 mt-1 bg-white border border-gray-300 rounded-sm"
        >
          <template v-for="item in group.items">
            <div class="py-2 mx-2 mt-0">
              <div class="flex">
                <toggle-button
                  :id="'toggle-'+item.key"
                  class="flex"
                  :value="itemValue(item.key)"
                  :sync="true"
                  @change="onChange(item.key, $event)"
                />
                <label
                  :for="'toggle-'+ item.key"
                  class="flex ml-3 text-sm text-gray-600"
                >{{ item.label }}</label>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {

  data () {
    return {
      groups: [
        {
          name: 'Content Features',
          open: true,
          items: [
            { label: 'Compositions', key: 'contentCompositionsEnabled' }
          ]
        },
        {
          name: 'Item Features',
          open: false,
          items: [
            { label: 'Share Embeds', key: 'itemEmbedsEnabled' },
            { label: 'Comments', key: 'itemCommentsEnabled' },
            { label: 'Activity', key: 'itemActivityEnabled' },
            { label: 'Versions', key: 'itemVersionsEnabled' },
            { label: 'Connections', key: 'itemConnectionsEnabled' }
          ]
        },
        {
          name: 'Editor',
          open: false,
          items: [
            { label: 'Camera Bookmark Inspector', key: 'editorCameraBookmarkInspectorEnabled' }
          ]
        },
        {
          name: 'App Features',
          open: false,
          items: [
            { label: 'Services & Integrations', key: 'appServicesEnabled' },
            { label: 'Billing', key: 'appBillingEnabled' }
          ]
        }
      ]
    }
  },

  methods: {

    itemValue (key) {
      return this.$store.getters['app/getFlag'](key)
    },
    onChange (key, evt) {
      this.$store.dispatch('app/setFlag', { key: key, value: evt.value })
    }
  }
}
</script>
